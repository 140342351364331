import React from "react";
import HeaderDark from "../components/Header-dark";
import ContactForm from "../components/contactForm";
import { Helmet } from "react-helmet";
import miniBanner from "../assets/img/miniBanner.png";
import WhyChooseUsOIS from "../components/WhyChooseUsOIS";
import Footer from "../components/Footer";
import Testimonials from "../components/Testimonials";
import { useNavigate } from "react-router-dom";

export default function overseasEducation() {
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();

    navigate("/contact");
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Best-overseas-eduction-consultancy-in-hyderabd | Acentrik</title>
        <meta
          name="description"
          content="Click here to Get in Touch with Us for Overseas Education Consultancy Services, Visa Services, Immigration Consultation, Student Loans and more."
        />
        <meta
          name="keywords"
          content="visa processing, Immigration Consultation, and a few keywords from above cell."
        />
      </Helmet>
      <HeaderDark />
      <div className="container-fluid" style={{ marginTop: "90px" }}>
        <div className="row">
          <div
            className="col-12"
            style={{
              //   backgroundImage: `url(${bannerImage})`,
              //   backgroundSize: "cover",
              background: "#ef7e00",
              padding: "20px",
            }}
          >
            <div className="row">
              <div
                className="col-7 text-center"
                style={{
                  margin: "auto",
                  width: "70%",
                  padding: "10px",
                }}
              >
                <h1 className="text-white">
                  Exploring Overseas Internship Opportunities: Your Path to
                  Global Experience
                </h1>
                <p className="text-white f18">
                  In today's competitive job market, practical experience is
                  paramount, and an overseas internship is a better way to gain
                  a global perspective. At Acentrik, we just don’t claim to be
                  the Best Visa Consultants in Hyderabad but We also be your
                  partner in achieving your dreams of pursuing an overseas
                  internship.
                </p>
              </div>
              <div className="col-5">
                <ContactForm />
              </div>
            </div>
          </div>
        </div>
      </div>
      <WhyChooseUsOIS />
      <Testimonials />
      <div
        className="container-fluid"
        style={{
          background: "#000",
          padding: "150px 80px",
          textAlign: "center",
          color: "#ffffff",
          backgroundImage: `url(${miniBanner})`,
          backgroundSize: "cover",
          marginTop: "60px",
        }}
      >
        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            <div className="miniBanner text-center">
              {/* <h5>Locations</h5> */}
              <h2>Discover more about our Overseas Internship Services</h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <br />
            <h2
              style={{ color: "#fff", fontSize: "22px", lineHeight: "1.8em" }}
            >
              As the best visa consultants in Hyderabad, we are here to ensure
              that your overseas internship journey is seamless, from visa
              processing to internship placement and beyond. Contact us today,
              and let's start planning your transformative overseas internship
              experience. Your global adventure awaits!
            </h2>
            <br />
            <button
              type="submit"
              name="submit"
              id="submit"
              onClick={handleSubmit}
              className="sendmailbtn"
            >
              Contact us now <i className="fa fa-paper-plane"></i>
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
