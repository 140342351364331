import React from "react";

function Testimonials() {
  return (
    <div className="advisor-area carousel-shadow default-padding">
      <div className="container">
        <div className="heading-left">
          <div className="row">
            <div className="col-lg-5">
              <h5>Testimonials</h5>
              <h2>Our Happy Clients</h2>
            </div>
            <div className="col-lg-6 offset-lg-1">
              <p>
                We're thrilled to share some heartfelt testimonials from our
                delighted clients. Their words of praise and satisfaction serve
                as a testament to our commitment to excellence and customer
                satisfaction.
              </p>
              {/* <a className="btn" href="#"></a> */}
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="advisor-items text-center">
          <div className="advisor-carousel owl-carousel owl-theme">
            {/* <!-- Single Item --> */}
            <div className="item">
              <div className="info">
                <p>
                  <i className="flaticon-quote"></i>I would strongly recommend
                  Acentrik consultancy for students who are aspiring to study
                  abroad. Acentrik consultancy has efficient counsellors who
                  will guide you throughout the process and make things easier
                  for you. Counsellors have been supportive throughout the
                  process. I can assure you that they'll all live up to your
                  expectations.
                </p>
              </div>
              <div className="info text-right">
                <h5>Chimmri Vishnu Vardhan</h5>
              </div>
            </div>
            {/* <!-- End Single Item --> */}
            {/* <!-- Single Item --> */}
            <div className="item">
              <div className="info">
                <p>
                  I am very happy to get my admission in the University of New
                  Haven with the help of Kavya. She helped me a lot from the
                  beginning to till now. It's an amazing experience with
                  Acentrik Consultancy.
                </p>
              </div>
              <div className="info text-right">
                <h5>Kommareddy Neelima</h5>
              </div>
            </div>
            {/* <!-- End Single Item --> */}
            {/* <!-- Single Item --> */}
            <div className="item">
              <div className="info">
                <p>
                  I had a very good experience with an accentrik consultancy.
                  Most of the application work and the follow up was done by
                  them and little to no work was done by me. Very happy with the
                  service and response I received and would definitely recommend
                  accentrik consultancy to anyone planning to pursue higher
                  studies abroad.
                </p>
              </div>
              <div className="info text-right">
                <h5>Dodda Sanjay Reddy</h5>
              </div>
            </div>
            {/* <!-- End Single Item --> */}
            {/* <!-- Single Item --> */}
            <div className="item">
              <div className="info">
                <p>
                  I had a very good experience with an accentrik consultancy.
                  Most of the application work and the follow up was done by
                  them and little to no work was done by me. Very happy with the
                  service and response I received and would definitely recommend
                  accentrik consultancy to anyone planning to pursue higher
                  studies abroad.
                </p>
              </div>
              <div className="info text-right">
                <h5>Dodda Sanjay Reddy</h5>
              </div>
            </div>
            {/* <!-- End Single Item --> */}
            {/* <!-- Single Item --> */}
            <div className="item">
              <div className="info">
                <p>
                  I had a very good experience with an accentrik consultancy.
                  Most of the application work and the follow up was done by
                  them and little to no work was done by me. Very happy with the
                  service and response I received and would definitely recommend
                  accentrik consultancy to anyone planning to pursue higher
                  studies abroad.
                </p>
              </div>
              <div className="info text-right">
                <h5>Dodda Sanjay Reddy</h5>
              </div>
            </div>
            {/* <!-- End Single Item --> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Testimonials;
