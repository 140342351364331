import React from "react";
import { contactHome } from "../image-imports";

function ContactHome() {
  return (
    <div className="facilities-area">
      <div className="container-fluid" style={{ background: "#f58635" }}>
        <div className="facilities-box">
          <div className="row">
            <div
              className="container info"
              style={{ paddingTop: "60px", paddingBottom: "45px" }}
            >
              <div className="">
                <div className="facilities-items">
                  <div className="item">
                    <div className="row">
                      <div className="col-md-5">
                        <div className="row">
                          <div
                            className="col-12"
                            style={{ padding: "60px 20px 20px 20px" }}
                          >
                            <img src={contactHome} alt="contactHome" />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-7">
                        <div className="row">
                          <div className="col-12" style={{ padding: "40px" }}>
                            <h1
                              style={{
                                color: "#fff",
                                fontWeight: "bold",
                                fontSize: "44px",
                              }}
                            >
                              Let's assist you <br /> in turning your amazing
                              dream into
                              <br /> a tangible reality.
                            </h1>
                            <a
                              className="btn btn-xs"
                              href="/contact"
                              style={{
                                padding: "12px 40px",
                                background: "#000",
                                color: "#fff",
                              }}
                            >
                              Contact Us
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactHome;
