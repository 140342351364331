import React from "react";

export default function whychooseUsIS() {
  return (
    <div className="why-choseus-area bg-gray default-padding bottom-less">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            <div className="site-heading text-center">
              <h5>Why Chose us</h5>
              <h2>
                Here's why you should choose Immigration Service at Acentrik:
              </h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <p>
              <span className="font-bold f16">
                Experienced Immigration Experts:
              </span>{" "}
              Our team of immigration professionals brings years of experience
              and in-depth knowledge of immigration laws and regulations for
              various countries. We stay updated with changes in immigration
              policies to ensure that your application is always in compliance
              with the most current requirements.
            </p>
            <p>
              <span className="font-bold f16">Personalized Approach:</span> We
              understand that each immigration case is unique in their own way.
              Whether you are an individual seeking a work visa, a student
              pursuing international education, a family looking to reunite, or
              an entrepreneur, we serve you according to your specific
              immigration service needs and circumstances.
            </p>
            <p>
              <span className="font-bold f16">Visa and Permit Assistance:</span>{" "}
              Navigating the visa and permit application process can be a
              hassle. We assist you in every aspect, from visa applications to
              work permits, study permits/overseas education services, permanent
              residency applications, and more. Our goal is to simplify the
              paperwork and guide you through the entire process with our
              seamless service.
            </p>
            <p>
              <span className="font-bold f16">Document Preparation: </span>We
              understand the significance of proper documentation in your
              immigration journey. Our team of experts help you in organizing
              and preparing all the necessary documents to make sure they meet
              the stringent requirements of immigration authorities of the
              respective countries.
            </p>
            <p>
              <span className="font-bold f16">Legal Compliance: </span>Rest
              assured that our immigration services are fully compliant with
              immigration laws and regulations. We give utmost priority to
              transparency and legal integrity in every step of the process to
              ensure a smooth and hassle-free experience.
            </p>
            <p>
              <span className="font-bold f16">Family Immigration: </span>Our
              immigration services extend to guiding you through the family
              sponsorship process, facilitating the immigration of your loved
              ones to build a new life together in the country of your choice.
            </p>
            <p>
              <span className="font-bold f16">Business Immigration: </span>For
              entrepreneurs and business professionals who look to expand their
              horizons, we offer specialized business immigration solutions. To
              leverage international opportunities, we assist you throughout
              your immigration journey.
            </p>
            <p>
              <span className="font-bold f16">Citizenship Applications: </span>
              We provide expert guidance in the citizenship application process,
              including naturalization requirements and procedures and support
              you in achieving your dreams.
            </p>
            <p>
              <span className="font-bold f16">Your Dreams, Our Expertise</span>
              Immigrating to a new country is not just a journey, it's a
              transformation. It presents a unique opportunity to immerse
              yourself in new cultures, forge a brighter future, and realize
              your aspirations. At Acentrik, we grasp the weight of your
              decision, and we are committed to assist you in accomplishing a
              seamless transition.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
