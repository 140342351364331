import React from "react";
import { Ielts, Toefl, pteAcademia } from "../image-imports";

function ServicesArea() {
  return (
    <>
      <div className="categories-area default-padding-bottom bottom-less about-padding-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <div className="site-heading text-center">
                <h5>Coaching</h5>
                <h2>Key Courses</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="course-details-area" style={{ paddingBottom: "120px" }}>
        <div
          className="container"
          style={{ paddingBottom: "30px", borderBottom: "3px solid red" }}
        >
          <div className="row">
            <div className="col-lg-8 info">
              <div className="top-info">
                <h2>IELTS (International English Language Testing System)</h2>
              </div>

              <div className="main-content">
                <div className="center-tabs">
                  <div id="tabsContent" className="tab-content">
                    <div
                      id="tab1"
                      className="tab-pane overview fade active show"
                    >
                      <h4>Course Description</h4>
                      <p className="text-justify">
                        IELTS (International English Language Testing System) is
                        an English language test for study, migration or work.
                        Over three million people take our test every year.
                        IELTS is accepted by more than 11,000 employers,
                        universities, schools and immigration bodies around the
                        world.
                      </p>
                      <p className="text-justify">
                        This course will help students develop their listening,
                        reading, writing and speaking skills in preparation for
                        the IELTS® Test. In this course students will learn test
                        taking strategies to help them reach their IELTS® test
                        score goal. Students will prepare specifically for the
                        listening and speaking modules, as well as the Academic
                        Reading and Writing components. Mini tests will be
                        administered throughout the course to track the
                        students’ progress. This elective course is open to
                        students whose English level is at the equivalent of
                        Level 5 or higher.
                      </p>
                      <h4>Learning Objectives</h4>
                      <ul>
                        <li>
                          Improve their listening, speaking, academic reading
                          and writing skills.
                        </li>
                        <li>
                          Develop test-taking strategies specifically for the
                          IELTS® test.
                        </li>
                        <li>
                          Improve their IELTS® test scores in all four skills.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 sidebar">
              <div className="item course-preview">
                <div className="content">
                  <div className="top">
                    <div className="pricce">
                      <img src={Ielts} alt="ielts coaching Acentrik" />
                    </div>
                  </div>
                  <div className="course-includes">
                    <ul>
                      <li>
                        <i
                          className="fas fa-coin"
                          style={{ fontSize: "20px", fontWeight: "bold" }}
                        >
                          $
                        </i>
                        Registration fee <span className="float-right">$0</span>
                      </li>
                      <li>
                        <i className="fas fa-clock"></i> Duration{" "}
                        <span className="float-right">2.45 Hrs</span>
                      </li>
                      <li style={{ height: "55px" }}>
                        <i className="fas fa-sliders-h"></i> Skill level{" "}
                        <span className="float-right">
                          High Intermediate to <br />
                          Advanced
                        </span>
                      </li>
                      <li>
                        <i className="fas fa-language"></i> Textbook{" "}
                        <span className="float-right">$40-$60</span>
                      </li>
                      <li>
                        <i className="fas fa-users"></i> Min Score <br />
                        Required <span className="float-right">6.0 – 7.0</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* second course */}
      <div className="course-details-area" style={{ paddingBottom: "120px" }}>
        <div
          className="container"
          style={{ paddingBottom: "30px", borderBottom: "3px solid red" }}
        >
          <div className="row">
            <div className="col-lg-8 info">
              <div className="top-info">
                <h2>TOEFL (Test of English as a Foreign Language)</h2>
              </div>

              <div className="main-content">
                <div className="center-tabs">
                  <div id="tabsContent" className="tab-content">
                    <div
                      id="tab1"
                      className="tab-pane overview fade active show"
                    >
                      <h4>Course Description</h4>
                      <p className="text-justify">
                        TOEFL® (Test of English as a Foreign Language) was
                        developed to address the problem of ensuring English
                        language proficiency for non-native speakers who wanted
                        to study at US universities.
                      </p>
                      <p className="text-justify">
                        However, the TOEFL iBT® test is generally accepted
                        around the world as proof of language proficiency for
                        other purposes as well, including work and immigration.
                      </p>
                      <h4>Learning Objectives</h4>
                      <ul>
                        <li>
                          Understand the format of each section of the TOEFL iBT
                          exam
                        </li>
                        <li>
                          Learn and apply different note-taking strategies
                          during the tes
                        </li>
                        <li>
                          Apply different test strategies to answer each section
                          of the test accurately
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 sidebar">
              <div className="item course-preview">
                <div className="content">
                  <div className="top">
                    <div className="pricce">
                      <img
                        src={Toefl}
                        alt="ielts coaching Acentrik"
                        style={{ width: "300px" }}
                      />
                    </div>
                  </div>
                  <div className="course-includes">
                    <ul>
                      <li>
                        <i
                          className="fas fa-coin"
                          style={{ fontSize: "20px", fontWeight: "bold" }}
                        >
                          $
                        </i>
                        Registration fee <span className="float-right">$0</span>
                      </li>
                      <li>
                        <i className="fas fa-clock"></i> Duration{" "}
                        <span className="float-right">3 Hrs</span>
                      </li>
                      <li style={{ height: "55px" }}>
                        <i className="fas fa-sliders-h"></i> Skill level{" "}
                        <span className="float-right">
                          High Intermediate to <br />
                          Advanced
                        </span>
                      </li>
                      <li>
                        <i className="fas fa-language"></i> Textbook{" "}
                        <span className="float-right">$40-$60</span>
                      </li>
                      <li>
                        <i className="fas fa-users"></i> Min Score <br />
                        Required <span className="float-right">65 – 100 </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Third course */}
      <div className="course-details-area" style={{ paddingBottom: "120px" }}>
        <div
          className="container"
          style={{ paddingBottom: "30px", borderBottom: "3px solid red" }}
        >
          <div className="row">
            <div className="col-lg-8 info">
              <div className="top-info">
                <h2>PTE Academic</h2>
              </div>

              <div className="main-content">
                <div className="center-tabs">
                  <div id="tabsContent" className="tab-content">
                    <div
                      id="tab1"
                      className="tab-pane overview fade active show"
                    >
                      <h4>Course Description</h4>
                      <p className="text-justify">
                        PTE Academic is an English language test developed by
                        Pearson. When compared to the other exams, it stands out
                        through the computer (AI-based) evaluation system and
                        the speed with which results are communicated to test
                        takers (usually, in less than 5 business days).
                      </p>
                      {/* <h4>Learning Objectives</h4>
                      <ul>
                        <li>
                          Understand the format of each section of the TOEFL iBT
                          exam
                        </li>
                        <li>
                          Learn and apply different note-taking strategies
                          during the tes
                        </li>
                        <li>
                          Apply different test strategies to answer each section
                          of the test accurately
                        </li>
                      </ul> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 sidebar">
              <div className="item course-preview">
                <div className="content">
                  <div className="top">
                    <div className="pricce">
                      <img src={pteAcademia} alt="ielts coaching Acentrik" />
                    </div>
                  </div>
                  <div className="course-includes">
                    <ul>
                      <li>
                        <i
                          className="fas fa-coin"
                          style={{ fontSize: "20px", fontWeight: "bold" }}
                        >
                          $
                        </i>
                        Registration fee <span className="float-right">$0</span>
                      </li>
                      <li>
                        <i className="fas fa-clock"></i> Duration{" "}
                        <span className="float-right">3 Hrs</span>
                      </li>
                      <li style={{ height: "55px" }}>
                        <i className="fas fa-sliders-h"></i> Skill level{" "}
                        <span className="float-right">
                          High Intermediate to <br />
                          Advanced
                        </span>
                      </li>
                      <li>
                        <i className="fas fa-language"></i> Textbook{" "}
                        <span className="float-right">$40-$60</span>
                      </li>
                      <li>
                        <i className="fas fa-users"></i> Min Score <br />
                        Required <span className="float-right">65 – 100 </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ServicesArea;
