import React from "react";
import Layout from "../components/Layouts";
import { Helmet } from "react-helmet";
import { backgroundImgFaq } from "../image-imports";
import Locations from "../components/locations";

function Faq() {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>FAQ's on Overseas Education</title>
        <meta
          name="description"
          content="We have listed out the Most Frequently Asked Questions related to Overseas Education, Masters in Abroad and much more to keep our users more informed. Click here view now."
        />
        <meta
          name="keywords"
          content="visa processing, Immigration Consultation, and a few keywords from above cell."
        />
      </Helmet>
      <div
        className="contact-Banner bg-cover shadow bg-gray"
        style={{
          backgroundImage: `url(${backgroundImgFaq})`,
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <h1>FAQ's</h1>
            </div>
          </div>
        </div>
      </div>
      <Locations />
    </Layout>
  );
}

export default Faq;
