import React from "react";

export default function WhyChooseUsOIS() {
  return (
    <div className="why-choseus-area bg-gray default-padding bottom-less">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            <div className="site-heading text-center">
              <h5>Why Chose us</h5>
              <h2>Why Choose Our Overseas Internship Services?</h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <p>
              <span className="font-bold f16">
                Expertise in Visa Processing:
              </span>{" "}
              As the best visa consultants in Hyderabad, we understand that
              securing the right visa is often the first challenge in the
              journey of pursuing an overseas internship. Our experienced team
              is well-versed in visa regulations and processes for various
              countries and we ensure a smooth and hassle-free visa application.
            </p>
            <p>
              <span className="font-bold f16">Vast Network of Partners:</span>{" "}
              Over the years, we have established a strong network of
              educational institutions, companies, and organizations across
              various countries. This network helps us to connect you with the
              best overseas internship opportunities that align with your field
              of study or career goals.
            </p>
            <p>
              <span className="font-bold f16">
                Customized Internship Placements:
              </span>{" "}
              We believe in the power of personalized experiences. Our team
              works closely with you to understand your academic and career
              objectives, ensuring that your internship placement matches your
              aspirations.
            </p>
            <p>
              <span className="font-bold f16">Interview Preparation:</span>
              Many overseas internships require interviews or assessments. Our
              experts offer interview coaching and guidance if needed, to boost
              your confidence and ensure you make a strong impression.
            </p>
            <p>
              <span className="font-bold f16">Safety and Support:</span>Your
              safety during your internship are our top priorities. We offer
              pre-departure guidance and provide assistance to ensure that you
              have a comfortable and secure experience abroad.
            </p>
            <p>
              <span className="font-bold f16">
                Why Pursue an Overseas Internship?
              </span>
              <ul>
                <li>Global Exposure</li>
                <li>Networking Opportunities</li>
                <li>Personal Development</li>
              </ul>
            </p>
            <p>
              Start Your Journey to Global Experience Begins Here
              <br />
              Embarking on an overseas internship is more than just an
              education. It's a chance to explore new horizons, make
              connections, and develop skills that will shape your future.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
