import React from "react";
import { Link } from "react-router-dom";

function Categories() {
  return (
    <div className="categories-area bottom-less" style={{ paddingTop: "80px" }}>
      <div className="container">
        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            <div className="site-heading text-center">
              <h5>Locations</h5>
              <h2>Our Offices</h2>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-12 text-center">
            <h2>Acentrik Technology Solutions LLC</h2>
            <p>
              1300 W Walnut hill Lane, <br />
              Suite 265E, <br />
              Irving,
              <br />
              TX 75038
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Categories;
