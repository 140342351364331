import React from "react";
import HeaderDark from "../components/Header-dark";
import ContactForm from "../components/contactForm";
import { Helmet } from "react-helmet";
import miniBanner from "../assets/img/miniBanner.png";
import WhyChooseUsOES from "../components/whychooseUsOES";
import Footer from "../components/Footer";
import Testimonials from "../components/Testimonials";
import { useNavigate } from "react-router-dom";

export default function overseasEducation() {
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();

    navigate("/contact");
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Best-overseas-eduction-consultancy-in-hyderabd | Acentrik</title>
        <meta
          name="description"
          content="Click here to Get in Touch with Us for Overseas Education Consultancy Services, Visa Services, Immigration Consultation, Student Loans and more."
        />
        <meta
          name="keywords"
          content="visa processing, Immigration Consultation, and a few keywords from above cell."
        />
      </Helmet>
      <HeaderDark />
      <div className="container-fluid" style={{ marginTop: "90px" }}>
        <div className="row">
          <div
            className="col-12"
            style={{
              //   backgroundImage: `url(${bannerImage})`,
              //   backgroundSize: "cover",
              background: "#ef7e00",
              padding: "20px",
            }}
          >
            <div className="row">
              <div
                className="col-7 text-center"
                style={{
                  margin: "auto",
                  width: "70%",
                  padding: "10px",
                }}
              >
                <h1 className="text-white">
                  Welcome to Our Overseas Education Services
                </h1>
                <p className="text-white f18">
                  We are the best Overseas Education consultants in Hyderabad as
                  we act as your companion throughout your journey for achieving
                  your aspirations of studying abroad. Our overseas education
                  services are meticulously crafted to lead you through each
                  phase of your global academic pursuit
                </p>
              </div>
              <div className="col-5">
                <ContactForm />
              </div>
            </div>
          </div>
        </div>
      </div>
      <WhyChooseUsOES />
      <Testimonials />
      <div
        className="container-fluid"
        style={{
          background: "#000",
          padding: "150px 80px",
          textAlign: "center",
          color: "#ffffff",
          backgroundImage: `url(${miniBanner})`,
          backgroundSize: "cover",
          marginTop: "60px",
        }}
      >
        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            <div className="miniBanner text-center">
              {/* <h5>Locations</h5> */}
              <h2>Discover more about Overseas Education services with Us</h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <br />
            <h2
              style={{ color: "#fff", fontSize: "22px", lineHeight: "1.8em" }}
            >
              We operate from various locations over AP & Telangana and offer
              our seamless Overseas Education Consultation. Visit your nearest
              branch or our support team is always available to connect and
              assist you according to your needs.
            </h2>
            <br />
            <button
              type="submit"
              name="submit"
              id="submit"
              onClick={handleSubmit}
              className="sendmailbtn"
            >
              Contact us now <i className="fa fa-paper-plane"></i>
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
