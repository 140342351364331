import React from "react";
import { cloudservices, aihome, securityhome } from "../image-imports";
import {
  features,
  Immigration,
  OverseasEdu,
  VisaApp,
  Internships,
  eduLoan,
  CoachingAssistance,
} from "../image-imports";

function ServicesHome() {
  return (
    <>
      <div className="categories-area default-padding-bottom bottom-less about-padding-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <div className="site-heading text-center">
                <h5>Services</h5>
                <h2>Core Services</h2>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="service-box text-light">
            <div className="row">
              {/* <!-- Single Item --> */}
              <div className="single-item col-lg-4 col-md-6">
                <div className="item">
                  <span></span>
                  <div
                    className="image"
                    style={{ backgroundImage: `url(${OverseasEdu})` }}
                  >
                    &nbsp;
                  </div>
                  <div className="title">
                    {/* <i className="flaticon-book-1"></i> */}
                    <h5 style={{ color: "#000", fontWeight: "bold" }}>
                      Product Development
                    </h5>
                  </div>
                  <div className="content">
                    <h4>
                      Acentrik Tech, offers a comprehensive range of services
                      designed to take innovative ideas from concept to market
                      reality. Our expert team specializes in ideation, concept
                      development, market research, design, prototyping, and
                      product engineering.
                    </h4>
                  </div>
                  <div className="link">
                    <a
                      data-animation="animated fadeInUp"
                      className="btn btn-xs btn-gradient"
                      href="/services"
                    >
                      Discover More
                    </a>
                  </div>
                </div>
              </div>
              {/* <!-- End Single Item --> */}
              {/* <!-- Single Item --> */}
              <div className="single-item col-lg-4 col-md-6">
                <div className="item">
                  <span></span>
                  <div
                    className="image"
                    style={{ backgroundImage: `url(${Immigration})` }}
                  >
                    &nbsp;
                  </div>
                  <div className="title">
                    {/* <i className="flaticon-book-1"></i> */}
                    <h5 style={{ color: "#000", fontWeight: "bold" }}>
                      Application Development & Support
                    </h5>
                  </div>
                  <div className="content">
                    <h4>
                      Acentrik Tech empower businesses to thrive in the digital
                      age. With a keen focus on innovation and customer-centric
                      solutions, our team of developers and support specialists
                      collaborates closely with clients to craft custom
                      applications tailored to their unique needs.
                    </h4>
                  </div>
                  <div className="link">
                    <a
                      data-animation="animated fadeInUp"
                      className="btn btn-xs btn-gradient"
                      href="/services"
                    >
                      Discover More
                    </a>
                  </div>
                </div>
              </div>
              {/* <!-- End Single Item --> */}
              {/* <!-- Single Item --> */}
              <div className="single-item col-lg-4 col-md-6">
                <div className="item">
                  <span></span>
                  <div
                    className="image"
                    style={{ backgroundImage: `url(${VisaApp})` }}
                  >
                    &nbsp;
                  </div>
                  <div className="title">
                    {/* <i className="flaticon-book-1"></i> */}
                    <h5 style={{ color: "#000", fontWeight: "bold" }}>
                      Intelligent Operations
                    </h5>
                  </div>
                  <div className="content">
                    <h4>
                      Acentrik Tech With a deep understanding of advanced
                      technologies such as artificial intelligence, machine
                      learning, and data analytics, helps organizations
                      streamline their processes, optimize resources, and make
                      data-driven decisions.
                    </h4>
                  </div>
                  <div className="link">
                    <a
                      data-animation="animated fadeInUp"
                      className="btn btn-xs btn-gradient"
                      href="/services"
                    >
                      Discover More
                    </a>
                  </div>
                </div>
              </div>
              {/* <!-- End Single Item --> */}
              {/* <!-- Single Item --> */}
              <div className="single-item col-lg-4 col-md-6">
                <div className="item">
                  <span></span>
                  <div
                    className="image"
                    style={{ backgroundImage: `url(${Internships})` }}
                  >
                    &nbsp;
                  </div>
                  <div className="title">
                    {/* <i className="flaticon-book-1"></i> */}
                    <h5 style={{ color: "#000", fontWeight: "bold" }}>
                      DevOps Engineering
                    </h5>
                  </div>
                  <div className="content">
                    <h4>
                      Acentrik Tech with sharp dedicated team of DevOps experts
                      specializes in creating seamless integration between
                      development and operations, enabling businesses to
                      accelerate their software delivery and enhance
                      collaboration among cross-functional teams.
                    </h4>
                  </div>
                  <div className="link">
                    <a
                      data-animation="animated fadeInUp"
                      className="btn btn-xs btn-gradient"
                      href="services"
                    >
                      Discover More
                    </a>
                  </div>
                </div>
              </div>
              {/* <!-- End Single Item --> */}
              {/* <!-- Single Item --> */}
              <div className="single-item col-lg-4 col-md-6">
                <div className="item">
                  <span></span>
                  <div
                    className="image"
                    style={{ backgroundImage: `url(${eduLoan})` }}
                  >
                    &nbsp;
                  </div>
                  <div className="title">
                    {/* <i className="flaticon-book-1"></i> */}
                    <h5 style={{ color: "#000", fontWeight: "bold" }}>
                      Test Automation
                    </h5>
                  </div>
                  <div className="content">
                    <h4>
                      Reshaping how businesses ensure the quality and
                      reliability with a team of skilled automation engineers.
                      With a complete test automation solutions, enable
                      organizations to streamline testing processes, reduce
                      human error, and accelerate time-to-market.
                    </h4>
                  </div>
                  <div className="link">
                    <a
                      data-animation="animated fadeInUp"
                      className="btn btn-xs btn-gradient"
                      href="services"
                    >
                      Discover More
                    </a>
                  </div>
                </div>
              </div>
              {/* <!-- End Single Item --> */}
              {/* <!-- Single Item --> */}
              <div className="single-item col-lg-4 col-md-6">
                <div className="item">
                  <span></span>
                  <div
                    className="image"
                    style={{ backgroundImage: `url(${CoachingAssistance})` }}
                  >
                    &nbsp;
                  </div>
                  <div className="title">
                    {/* <i className="flaticon-book-1"></i> */}
                    <h5 style={{ color: "#000", fontWeight: "bold" }}>
                      Help Desk
                    </h5>
                  </div>
                  <div className="content">
                    <h4>
                      Top-notch Help Desk services to provide unparalleled
                      support and assistance to their customers. With a
                      customer-centric approach, Acentrik Tech's Help Desk team
                      is dedicated to resolving issues promptly, ensuring that
                      clients can maintain smooth and uninterrupted operations.
                    </h4>
                  </div>
                  <div className="link">
                    <a
                      data-animation="animated fadeInUp"
                      className="btn btn-xs btn-gradient"
                      href="services"
                    >
                      Discover More
                    </a>
                  </div>
                </div>
              </div>
              {/* <!-- End Single Item --> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ServicesHome;
