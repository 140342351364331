import React from "react";
import HeaderDark from "../components/Header-dark";
import ContactForm from "../components/contactForm";
import { Helmet } from "react-helmet";
import miniBanner from "../assets/img/miniBanner.png";
import WhyChooseUsIS from "../components/WhyChooseUsIS";
import Footer from "../components/Footer";
import Testimonials from "../components/Testimonials";
import { useNavigate } from "react-router-dom";

export default function overseasEducation() {
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();

    navigate("/contact");
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Best-overseas-eduction-consultancy-in-hyderabd | Acentrik</title>
        <meta
          name="description"
          content="Click here to Get in Touch with Us for Overseas Education Consultancy Services, Visa Services, Immigration Consultation, Student Loans and more."
        />
        <meta
          name="keywords"
          content="visa processing, Immigration Consultation, and a few keywords from above cell."
        />
      </Helmet>
      <HeaderDark />
      <div className="container-fluid" style={{ marginTop: "90px" }}>
        <div className="row">
          <div
            className="col-12"
            style={{
              //   backgroundImage: `url(${bannerImage})`,
              //   backgroundSize: "cover",
              background: "#ef7e00",
              padding: "20px",
            }}
          >
            <div className="row">
              <div
                className="col-7 text-center"
                style={{
                  margin: "auto",
                  width: "70%",
                  padding: "10px",
                }}
              >
                <h1 className="text-white">
                  Welcome to Our Comprehensive Immigration Services
                </h1>
                <p className="text-white f18">
                  Are you dreaming of a new life in a foreign country? Whether
                  you're seeking better career opportunities, pursuing higher
                  education, reuniting with family, or simply looking for a
                  change of scenery, immigrating to a new country can be a
                  life-altering decision.
                </p>
              </div>
              <div className="col-5">
                <ContactForm />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="container" style={{ padding: "40px" }}>
            <p className="f16">
              As your trusted immigration service consultants in Hyderabad,
              Acentrik comprehends the intricacies and hurdles of this process,
              and we are dedicated to guiding you through each phase of your
              immigration journey.
            </p>
            <h3>Our Commitment to Serve You Better</h3>
            <p className="f16">
              At Acentrik.in, we take pride in being your trusted partner in
              achieving your immigration goals. Our comprehensive immigration
              services are designed to provide you with expert guidance and
              support throughout the entire immigration process.
            </p>
          </div>
        </div>
      </div>
      <WhyChooseUsIS />
      <Testimonials />
      <div
        className="container-fluid"
        style={{
          background: "#000",
          padding: "150px 80px",
          textAlign: "center",
          color: "#ffffff",
          backgroundImage: `url(${miniBanner})`,
          backgroundSize: "cover",
          marginTop: "60px",
        }}
      >
        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            <div className="miniBanner text-center">
              {/* <h5>Locations</h5> */}
              <h2>
                Discover more about our Comprehensive Immigration Services
              </h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <br />
            <h2
              style={{ color: "#fff", fontSize: "22px", lineHeight: "1.8em" }}
            >
              Our immigration services will act as your bridge to a world of
              opportunities. Contact Us today and start your journey towards a
              future that you always dreamt for.
            </h2>
            <br />
            <button
              type="submit"
              name="submit"
              id="submit"
              onClick={handleSubmit}
              className="sendmailbtn"
            >
              Contact us now <i className="fa fa-paper-plane"></i>
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
