import React from "react";
import { logoLight } from "../image-imports";
import { Link } from "react-router-dom";

function HeaderDark() {
  return (
    <div className="container-fluid" id="home">
      <div className="row">
        <nav className="navbar navbar-default navbar-fixed bootsnav">
          <div className="container">
            <div className="attr-nav">
              <ul>
                <li className="search">
                  <a href="#">
                    <i className="ti-search"></i>
                  </a>
                </li>
              </ul>
            </div>

            <div className="navbar-header">
              <button
                type="button"
                className="navbar-toggle"
                data-toggle="collapse"
                data-target="#navbar-menu"
              >
                <i className="fa fa-bars"></i>
              </button>
              <a className="navbar-brand" href="/">
                <img src={logoLight} className="logo logo-display" alt="Logo" />
                <img
                  src={logoLight}
                  className="logo logo-scrolled"
                  alt="Logo"
                />
              </a>
            </div>

            <div className="collapse navbar-collapse" id="navbar-menu">
              <ul
                className="nav navbar-nav navbar-right"
                data-in="fadeInDown"
                data-out="fadeOutUp"
              >
                <li>
                  <Link to="/">Home</Link>
                </li>

                {/* <li className="dropdown">
                <a href="#" className="dropdown-toggle" data-toggle="dropdown">
                  About Us
                </a>
                <ul className="dropdown-menu">
                  <li>
                    <a href="AboutUs">About Us</a>
                  </li>
                  <li>
                    <a href="Team">Team</a>
                  </li>
                  <li>
                    <a href="Partners">Partners</a>
                  </li>
                </ul>
              </li> */}
                <li>
                  <Link to="/AboutUs">About Us</Link>
                </li>
                <li>
                  <Link to="/services">Services</Link>
                </li>
                <li>
                  <Link to="/coaching">Coaching</Link>
                </li>
                <li>
                  <Link to="/universities">Universities</Link>
                </li>
                <li>{/* <Link to="blog">Blog</Link> */}</li>
                <li>{/* <Link to="faq">FAQ's</Link> */}</li>
                <li>
                  <Link to="/contact">Contact</Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
}

export default HeaderDark;
