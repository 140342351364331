import React from "react";

function Footer({ mode }) {
  return (
    <footer className={mode === "light" ? "" : "bg-dark text-light"}>
      <div className="container">
        <div className="f-items default-padding">
          <div className="row">
            <div className="col-lg-4 col-md-6 item">
              <div className="f-item about">
                <h4 className="widget-title">About</h4>
                <p>
                  Acentrik Tech is a global IT services provider and a digital
                  transformation partner focused upon providing great value to
                  clients worldwide with cost-effective, integrated, responsive
                  and trustworthy IT solutions.
                </p>
                <div className="social">
                  <h5>Follow Us</h5>
                  <ul>
                    <li className="facebook">
                      <a href="https://www.facebook.com/acentrikconsultancyservices/">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                    </li>
                    <li className="twitter">
                      <a href="https://twitter.com/AcentrikConsult">
                        <i className="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li className="instagram">
                      <a href="https://www.instagram.com/ascentrikconsultancyservices">
                        <i className="fab fa-instagram"></i>
                      </a>
                    </li>
                    <li className="linkedin">
                      <a href="https://www.linkedin.com/company/acentrik-consultancy-services">
                        <i className="fab fa-linkedin-in"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 item">
              <div className="f-item link">
                <h4 className="widget-title">Quick Links</h4>
                <ul>
                  <li>
                    <a href="AboutUs">About Us</a>
                  </li>
                  <li>
                    <a href="services">Services</a>
                  </li>
                  <li>{/* <a href="careers">Careers</a> */}</li>
                  <li>
                    <a href="contact">Contact</a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 item">
              <div className="f-item contact">
                <h4 className="widget-title">Contact Info</h4>
                <div className="address">
                  <ul>
                    <li>
                      <strong>Address:</strong> 1300 W Walnut hill Lane, Suite
                      265E, Irving,TX 75038
                    </li>
                    <li>
                      <strong>Email:</strong> hr@acentriktech.com
                    </li>
                    <li>
                      <strong>Contact:</strong> +1 (469) 670-4364
                    </li>
                  </ul>
                </div>
                <div className="opening-info">
                  <h5>Opening Hours</h5>
                  <ul>
                    <li>
                      <span> Sun - Thu :8:30 AM - 9 PM</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Footer Bottom --> */}
      <div className="footer-bottom">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <p>
                &copy; Copyright 2023. All Rights Reserved by{" "}
                <a href="/">Acentrik Tech</a>
              </p>
            </div>
            <div className="col-lg-6 text-right link">
              <ul>
                <li>
                  <a href="#">Terms</a>
                </li>
                <li>
                  <a href="#">Privacy</a>
                </li>
                <li>
                  <a href="#">Support</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- End Footer Bottom --> */}
    </footer>
  );
}

export default Footer;
