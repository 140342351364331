import React from "react";
import {
  backgroundImgUSA,
  backgroundImgUK,
  backgroundImgCAN,
  backgroundImgAUZ,
  backgroundImgNZ,
  backgroundImgGer,
} from "../image-imports";

function Universities() {
  return (
    <div
      className="categories-area default-padding-bottom bottom-less"
      style={{ marginTop: "120px" }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            <div className="site-heading text-center">
              <h5>COUNTRIES</h5>
              <h2>POPULAR COUNTRIES</h2>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="category-box text-light">
          <div className="row">
            {/* <!-- Single Item --> */}
            <div className="single-item col-lg-4 col-md-6">
              <div
                className="item"
                style={{ backgroundImage: `url(${backgroundImgUSA})` }}
              >
                <a href="#">
                  <span>58 Universities</span>
                  <div className="title">
                    {/* <i className="flaticon-innovation"></i> */}
                    <h4>United States</h4>
                  </div>
                </a>
              </div>
            </div>
            {/* <!-- End Single Item --> */}
            {/* <!-- Single Item --> */}
            <div className="single-item col-lg-4 col-md-6">
              <div
                className="item"
                style={{ backgroundImage: `url(${backgroundImgUK})` }}
              >
                <a href="#">
                  <span>25 Universities</span>
                  <div className="title">
                    {/* <i className="flaticon-meeting"></i> */}
                    <h4>United Kingdoms</h4>
                  </div>
                </a>
              </div>
            </div>
            {/* <!-- End Single Item --> */}
            {/* <!-- Single Item --> */}
            <div className="single-item col-lg-4 col-md-6">
              <div
                className="item"
                style={{ backgroundImage: `url(${backgroundImgCAN})` }}
              >
                <a href="#">
                  <span>33 Universities</span>
                  <div className="title">
                    {/* <i className="flaticon-engineer-1"></i> */}
                    <h4>Canada</h4>
                  </div>
                </a>
              </div>
            </div>
            {/* <!-- End Single Item --> */}
            {/* <!-- Single Item --> */}
            <div className="single-item col-lg-4 col-md-6">
              <div
                className="item"
                style={{ backgroundImage: `url(${backgroundImgAUZ})` }}
              >
                <a href="#">
                  <span>25 Universities</span>
                  <div className="title">
                    {/* <i className="flaticon-science"></i> */}
                    <h4>Australia</h4>
                  </div>
                </a>
              </div>
            </div>
            {/* <!-- End Single Item --> */}
            {/* <!-- Single Item --> */}
            <div className="single-item col-lg-4 col-md-6">
              <div
                className="item"
                style={{ backgroundImage: `url(${backgroundImgNZ})` }}
              >
                <a href="#">
                  <span>25 Universities</span>
                  <div className="title">
                    {/* <i className="flaticon-laptop"></i> */}
                    <h4>New Zealand</h4>
                  </div>
                </a>
              </div>
            </div>
            {/* <!-- End Single Item --> */}
            {/* <!-- Single Item --> */}
            <div className="single-item col-lg-4 col-md-6">
              <div
                className="item"
                style={{ backgroundImage: `url(${backgroundImgGer})` }}
              >
                <a href="#">
                  <span>33 Universities</span>
                  <div className="title">
                    {/* <i className="flaticon-book-1"></i> */}
                    <h4>Germany</h4>
                  </div>
                </a>
              </div>
            </div>
            {/* <!-- End Single Item --> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Universities;
