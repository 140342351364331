import React from "react";
import { backgroundImg800x600, dottedShape, img1202 } from "../image-imports";
import whyChooseUsImg from "../assets/img/whychooseus.jpg";

export default function whychooseUsOES() {
  return (
    <div className="why-choseus-area bg-gray default-padding bottom-less">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            <div className="site-heading text-center">
              <h5>Why Chose us</h5>
              <h2>Why Choose Overseas Education Services of Acentrik?</h2>
              <br />
              <h5>
                We become a part of your journey by assisting you throughout the
                process.
              </h5>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <p>
              <span className="font-bold f16">Expert Guidance:</span> Our team
              of experienced counselors and advisors are well-versed in the
              intricacies of international education systems. They will provide
              you with personalized guidance to help you make informed
              decisions.
            </p>
            <p>
              <span className="font-bold f16">University Selection:</span> We
              assist you in selecting the right universities or colleges that
              align with your academic goals, budget, and preferences.
            </p>
            <p>
              <span className="font-bold f16">Application Support:</span> From
              preparing your application documents to filling out forms and
              ensuring they meet the required standards, we are here to make the
              overseas education application process seamless.
            </p>
            <p>
              <span className="font-bold f16">Visa Assistance: </span>Navigating
              the visa process can be complex. Our experts will ensure you have
              all the necessary documentation and guide you through the visa
              application process.
            </p>
            <p>
              <span className="font-bold f16">Test Preparation: </span>We offer
              resources and coaching to help you excel in standardized tests
              like TOEFL, IELTS, GRE, GMAT, and others to achieve your dream of
              overseas education.
            </p>
            <p>
              <span className="font-bold f16">Financial Planning: </span>Our
              team will assist you in exploring scholarship opportunities and
              financial aid options to make your education more affordable.
            </p>
            <p>
              <span className="font-bold f16">Pre-Departure Guidance: </span>We
              provide pre-departure orientations to ensure you are well-prepared
              for life abroad, from accommodation arrangements to understanding
              the culture.
            </p>
            <p>
              <span className="font-bold f16">Continuous Support: </span>Our
              Support will not end with your departure. We provide ongoing
              support to address any concerns or challenges you may face during
              your Overseas Education journey at the maximum of our potential.
            </p>
            <p>
              <span className="font-bold f16">
                Your Path to a Global Education Begins Here:
              </span>
              Studying abroad can be a life-changing experience, and we are
              dedicated to making it accessible and hassle-free for you. Whether
              you aspire to pursue undergraduate, postgraduate, or doctoral
              studies, our overseas education services are tailored to your
              unique aspirations.
            </p>
            <p>
              Join the ranks of our successful international students who have
              ventured beyond borders to broaden their horizons and achieve
              their academic and career goals. Contact us today to schedule a
              consultation and take the first step towards your overseas
              education journey. Your future awaits!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
