import React from "react";
import HeaderDark from "../components/Header-dark";
import ContactForm from "../components/contactForm";
import { Helmet } from "react-helmet";
import miniBanner from "../assets/img/miniBanner.png";
import WhyChooseUsVPS from "../components/WhyChooseUsVPS";
import Footer from "../components/Footer";
import Testimonials from "../components/Testimonials";
import { useNavigate } from "react-router-dom";

export default function overseasEducation() {
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();

    navigate("/contact");
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Best-overseas-eduction-consultancy-in-hyderabd | Acentrik</title>
        <meta
          name="description"
          content="Click here to Get in Touch with Us for Overseas Education Consultancy Services, Visa Services, Immigration Consultation, Student Loans and more."
        />
        <meta
          name="keywords"
          content="visa processing, Immigration Consultation, and a few keywords from above cell."
        />
      </Helmet>
      <HeaderDark />
      <div className="container-fluid" style={{ marginTop: "90px" }}>
        <div className="row">
          <div
            className="col-12"
            style={{
              //   backgroundImage: `url(${bannerImage})`,
              //   backgroundSize: "cover",
              background: "#ef7e00",
              padding: "20px",
            }}
          >
            <div className="row">
              <div
                className="col-7 text-center"
                style={{
                  margin: "auto",
                  width: "70%",
                  padding: "10px",
                }}
              >
                <h1 className="text-white">
                  Welcome to Our Visa Processing Services
                </h1>
                <p className="text-white f18">
                  When it comes to embarking on international journeys, be it
                  for education, work, travel, or family reunification,
                  obtaining the appropriate visa is often the first and most
                  crucial step. At Acentrik, we take pride in being the best
                  visa consultants in Hyderabad, offering a comprehensive suite
                  of visa processing services to make your global aspirations a
                  reality.
                </p>
              </div>
              <div className="col-5">
                <ContactForm />
              </div>
            </div>
          </div>
        </div>
      </div>
      <WhyChooseUsVPS />
      <Testimonials />
      <div
        className="container-fluid"
        style={{
          background: "#000",
          padding: "150px 80px",
          textAlign: "center",
          color: "#ffffff",
          backgroundImage: `url(${miniBanner})`,
          backgroundSize: "cover",
          marginTop: "60px",
        }}
      >
        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            <div className="miniBanner text-center">
              {/* <h5>Locations</h5> */}
              <h2>Discover more about our Visa Processing Services</h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <br />
            <h2
              style={{ color: "#fff", fontSize: "22px", lineHeight: "1.8em" }}
            >
              Ready to take your first step toward an international journey?
              Contact the best visa consultants in Hyderabad at Acentrik. Let us
              navigate the complexities of visa processing while you focus on
              your dreams and aspirations. Reach Us as we are here to make it
              happen.
            </h2>
            <br />
            <button
              type="submit"
              name="submit"
              id="submit"
              onClick={handleSubmit}
              className="sendmailbtn"
            >
              Contact us now <i className="fa fa-paper-plane"></i>
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
