import React from "react";
import {
  features,
  Immigration,
  OverseasEdu,
  VisaApp,
  Internships,
  eduLoan,
  CoachingAssistance,
} from "../image-imports";
import ServicesHome from "../components/Serviceshome";

function ServicesArea() {
  return (
    <>
      <ServicesHome />
      <div className="container">
        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            <div className="site-heading text-center">
              {/* <h5>Features</h5> */}
              <h2> IT Consulting</h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 ">
            <div className="site-heading text-center">
              <p>
                We simplify the complexity between ideation, technology, and
                execution. We’ve helped clients with IT Consulting advisory
                services by assessing different technology strategies and
                aligning their technology strategies with their business and
                process strategies.
              </p>
              <p>
                Our passionate IT consultants provide strategic business and
                technical guidance to clients.Our consulting company works with
                clients to help them solve IT problems.We help businesses create
                value and optimize their performance through technology.We
                undertakestrategic or operationalmissions, and help our clients
                in implementation,development or rolling out software to respond
                to a specific need,improvingthe efficiency of systems, and
                helping their businesses best use IT to meet its goals. OurIT
                consulting focuses on providing strategic IT advice on how to
                modify or improve solutions to reach the desired goal.
              </p>
              <p>We provide IT Consulting Services for</p>
              <ul>
                <li>Regular technology planning</li>
                <li>IT Budgeting</li>
                <li>Planned Upgrades</li>
                <li>Technical Design</li>
                <li>Best-practice guidance</li>
                <li>Access to Technology Design Architects</li>
              </ul>
            </div>
          </div>
          <div
            className="col-lg-6 features-right"
            style={{ backgroundImage: `url(${features})` }}
          ></div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            <div className="site-heading text-center">
              {/* <h5>Features</h5> */}
              <h2> Application Modernization</h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 ">
            <div className="site-heading text-center">
              <p>
                Enterprise organizations are transforming legacy processes to
                flexible, modular application development management portfolios
                by implementing next-generation processes defined by speed,
                efficiency and resilience. Organizations are adopting the agile
                approach in application development to enhance their digital
                transformation smartly, while keeping pace with the competition
                and the changing marketplace with positive customer experiences.
              </p>
              <p>
                Acentrik can help in fulfilling your vision of software
                development process by understanding the complexities and
                nuances of your company while designing and implementing
                solutions that will align with your technology environment.
              </p>
              <p>
                Process Improvement: Custom software streamlines and accentuates
                what makes your company stand out, while increasing
                productivity, capacity, and scalability.
              </p>
              <p>
                Business First Approach: We design your business software based
                on your needs and your vision, not by trying to force your
                business into prepackaged programs or preconceived notions and
                making it fit.
              </p>
              <p>
                Team Work: Taking a deeper look at the needs of your business
                and people, we work with your team to build the exact software
                system you are looking for.
              </p>
              <p>We provide Application Modernization Services for</p>
              <ul>
                <li>Product Development</li>
                <li>Application Development & Support</li>
                <li>Intelligent Operations</li>
                <li>DevOps Engineering</li>
                <li>Test Automation</li>
                <li>Help Desk</li>
              </ul>
            </div>
          </div>
          <div
            className="col-lg-6 features-right"
            style={{ backgroundImage: `url(${features})` }}
          ></div>
        </div>
      </div>
    </>
  );
}

export default ServicesArea;
