import React from "react";
import Layout from "../components/Layouts";
import Banner from "../components/Banner";
import Facilities from "../components/Facilities";
import ServicesHome from "../components/Serviceshome";
import ContactHome from "../components/Contacthome";
import Services from "../components/Services";

import { Helmet } from "react-helmet";

export default function Home() {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Global IT services provider and a digital transformation partner |
          Acentrik Tech
        </title>
        <meta name="description" content=" " />
        <meta name="keywords" content=" " />
      </Helmet>
      <Banner />
      <Facilities />
      <ServicesHome />
      <ContactHome />
    </Layout>
  );
}
