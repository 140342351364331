import React from "react";
import Layout from "../components/Layouts";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { backgroundImgServices } from "../image-imports";
import ServicesArea from "../components/Services";
import CoachingArea from "../components/coaching";
import Testimonials from "../components/Testimonials";

function ContactLocation() {
  const { location } = useParams();

  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Contact Us Now | Acentrik</title>
        <meta
          name="description"
          content="Click here to Get in Touch with Us for Overseas Education Consultancy Services, Visa Services, Immigration Consultation, Student Loans and more."
        />
        <meta
          name="keywords"
          content="visa processing, Immigration Consultation, and a few keywords from above cell."
        />
      </Helmet>
      <div
        className="contact-Banner bg-cover shadow bg-gray"
        style={{
          backgroundImage: `url(${backgroundImgServices})`,
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <h1>
                {/* Contact US <br /> */}
                {location.split("-").join(" ")}
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="site-heading  mt-4" style={{ paddingTop: "80px" }}>
        <h3 className="text-center text-capitalize">Welcome to</h3>
        <h2 className="text-center text-capitalize">
          {location.split("-").join(" ")}
        </h2>
      </div>
      <div className="container" style={{ paddingTop: "60px" }}>
        <div className="row">
          <div className="col-lg-12">
            <p className="aboutP">
              <span>15+ Years of Your Trust</span> and Recommendation, Acentrik
              Consultancy Services was founded in 2007 with the vision of
              providing unparalleled services to the students seeking student
              visas. We started our Journey from February 2008 in Hyderabad and
              now we are one of the fastest growing Overseas Study consultants
              In Hyderabad. Acentrik also expanded its wings in both andhra
              pradesh and telangana in various locations
            </p>
          </div>
        </div>
      </div>
      {/* services */}
      <ServicesArea />
      <div
        className="advisor-area default-padding bottom-less"
        style={{ background: "#f2f2f2" }}
      >
        <div className="container">
          <div className="heading-left">
            <div className="row">
              <div className="col-lg-5">
                <h5>Course Advisor</h5>
                <h2>Our professional &amp; Expert Course advisor</h2>
              </div>
              <div className="col-lg-6 offset-lg-1">
                <p>
                  Unlock the path to a successful and fulfilling career with our
                  expert counseling. Our seasoned professionals provide
                  personalized guidance, helping you identify your strengths,
                  passions, and ideal career trajectory. We're here to support
                  your journey towards making informed decisions and achieving
                  your career aspirations. Trust in our expertise to steer you
                  toward a brighter and more prosperous future.
                </p>
                <a className="btn btn-md btn-dark border" href="contact">
                  Get Advise <i className="fas fa-plus"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CoachingArea />
      <div style={{ background: "#ccc" }}>
        <Testimonials />
      </div>
    </Layout>
  );
}

export default ContactLocation;
