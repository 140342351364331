import React from "react";
import Layout from "../components/Layouts";
import { Helmet } from "react-helmet";
import { backgroundImgServices } from "../image-imports";

function Team() {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>View all the Visa Services We provide</title>
        <meta
          name="description"
          content="We are Known for Our Services in Overseas Education Consultancy, where we also provide exceptional Visa Services in Hyderabad, Immigration consultancy, Education loans, student loans and much more. View them now."
        />
        <meta
          name="keywords"
          content="visa processing, Immigration Consultation, and a few keywords from above cell."
        />
      </Helmet>
      <div
        className="contact-Banner bg-cover shadow bg-gray"
        style={{
          backgroundImage: `url(${backgroundImgServices})`,
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <h1>Our Team</h1>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Team;
