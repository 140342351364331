import React from "react";

export default function whychooseUsIS() {
  return (
    <div className="why-choseus-area bg-gray default-padding bottom-less">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            <div className="site-heading text-center">
              <h5>Why Chose us</h5>
              <h2>
                Here's why you should choose Immigration Service at Acentrik:
              </h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <p>
              <span className="font-bold f16">Expert Guidance:</span> Our team
              of well-trained Visa consultants in Hyderabad possesses extensive
              knowledge of visa regulations, procedures, and requirements for
              various countries across the world. We provide you with expert
              guidance specific to your visa needs.
            </p>
            <p>
              <span className="font-bold f16">Comprehensive Services:</span> Be
              it a tourist visa, student visa, work visa, business visa, we
              cover it all. Our services include the entire visa application
              process, from documentation to submission, tracking, and
              follow-up.
            </p>
            <p>
              <span className="font-bold f16">Personalized Approach:</span> We
              understand that every visa application is unique, and we treat it
              as such. Our visa consultants work closely with you to assess your
              individual requirements to ensure that your application is
              accurately prepared and submitted.
            </p>
            <p>
              <span className="font-bold f16">
                Visa Appointment Scheduling:{" "}
              </span>
              We also assist you with the often time-consuming task of
              scheduling visa appointments, ensuring that you secure a
              convenient date and time.
            </p>
            <p>
              <span className="font-bold f16">Interview Preparation </span>Many
              countries require visa applicants to attend an interview. Our
              consultants provide you with valuable insights, tips, and mock
              interview sessions if needed to boost your confidence and
              readiness.
            </p>
            <p>
              <span className="font-bold f16">
                Your Passport to the World Begins Here
              </span>
              Obtaining a visa is not just about traveling, it's about unlocking
              a world of opportunities. We are here to be your trusted partners
              in achieving your dreams, be it studying at a prestigious
              international institution, advancing your career overseas,
              exploring new horizons, or reuniting with loved ones.
            </p>
            <p>
              Our visa processing services in Hyderabad are designed to make the
              application process smooth, transparent, and efficient. We ensure
              that your application complies with all the necessary regulations,
              increasing your chances of visa approval.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
