import React from "react";
import { backgroundImg800x600, dottedShape, img1202 } from "../image-imports";
import whyChooseUsImg from "../assets/img/whychooseus.jpg";

export default function whychooseUs() {
  return (
    <div className="why-choseus-area bg-gray default-padding bottom-less">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            <div className="site-heading text-center">
              <h5>Why Chose us</h5>
              <h2>
                Everything you Need to know about <br />
                Acentrik Tech
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div className="container-full">
        <div className="info">
          <div className="row">
            <div className="single-item thumb col-lg-5">
              <div className="thumb-box">
                <img src={whyChooseUsImg} alt="Thumb" />
              </div>
            </div>

            <div className="item-box col-lg-7">
              <div className="row">
                <div className="single-item col-lg-6 col-md-6">
                  <div className="item">
                    <span>01</span>
                    {/* <i className="flaticon-library"></i> */}
                    <h4>Delivery and strategy rooted in core values</h4>
                    <p>
                      We take pride in offering products and services of great
                      value, backed by our commitment to ensure customer
                      satisfaction, profitability, and the well-being of our
                      employees, all of which contribute to our future growth
                    </p>
                  </div>
                </div>
                <div className="single-item col-lg-6 col-md-6">
                  <div className="item">
                    <span>02</span>
                    {/* <i className="flaticon-teacher-2"></i> */}
                    <h4>Enhancements to processes</h4>
                    <p>
                      We tirelessly strive for ongoing enhancement and
                      excellence in projects, products, processes, and services,
                      defining success as the achievement of superior results
                      through effective execution.
                    </p>
                  </div>
                </div>
                <div className="single-item col-lg-6 col-md-6">
                  <div className="item">
                    <span>03</span>
                    {/* <i className="flaticon-teacher-2"></i> */}
                    <h4>Teamwork & People</h4>
                    <p>
                      We nurture a workplace culture that promotes and rewards
                      creative thinking, aiming to generate avenues for process
                      enhancement and the delivery of cost-effective solutions
                      and services.
                    </p>
                  </div>
                </div>
                <div className="single-item col-lg-6 col-md-6">
                  <div className="item">
                    <span>04</span>
                    {/* <i className="flaticon-teacher-2"></i> */}
                    <h4>Customer Centric</h4>
                    <p>
                      We acknowledge that our customers are the driving force
                      behind our business success, and we are dedicated to
                      actively listening and responding to their requirements.
                      We wholeheartedly provide our utmost service excellence in
                      all our endeavors
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
