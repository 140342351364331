import React from "react";
import { backgroundImg800x600, dottedShape, img1202 } from "../image-imports";
import WhyChooseUs from "../components/whychooseUs";
import ContactHome from "../components/Contacthome";

function About() {
  return (
    <>
      <div className="container" style={{ paddingTop: "60px" }}>
        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            <div className="site-heading text-center">
              <h5>About Acentrik Tech</h5>
              <h2>
                Global IT services provider and a digital transformation partner
              </h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <p className="aboutP">
              Acentrik Technology Solutions LLC is an Information Technology
              consulting services company. We deliver advisory, professional
              managed services to enterprises and clients looking for value,
              flexibility and excellent service. As a company, our vision is to
              create a desirable centralized marketplace and contribute to
              increasing our client’s overall business goals and objectives.
            </p>
            <p className="aboutP">
              We provide techno-functional consulting experts in cutting edge
              technologies by following the best industry practices and
              delivering value to our clients.We evaluate business challenges
              through the lens of value creation.We lead with aclear goal on how
              we can help clients make operations more efficient. Our technology
              consultants develop trusted partnerships with the clients we
              serve, by providing savvy services in Java, .Net, C#, Database
              Management, Data Engineering, Data Science, Oracle, Salesforce,
              Software Development & IT Operations, Mulesoft and other
              middleware services.
            </p>
          </div>
        </div>
      </div>
      <div className="about-area default-padding-top about-padding-top">
        <div className="fixed-shape-bottom">
          <img src={dottedShape} alt="Thumb" />
        </div>
        <div className="container">
          <div className="about-items">
            <div className="row align-center">
              <div className="col-lg-6 info">
                <h5>Our Vision</h5>
                <h2>
                  We are motivated by the purpose of fostering the <br />
                  growth of your business.
                </h2>
                <ul>
                  <li>
                    Assisting our customers in achieving faster milestones and
                    reaching the market with their breakthrough products.
                  </li>
                  <li>
                    To continually forge fresh growth prospects for your
                    strategic business.
                  </li>
                  <li>
                    Dedicatedly assisting businesses in leveraging innovative
                    technologies to achieve their entrepreneurial aspirations
                  </li>
                  <li>
                    To cultivate enduring client relationships through the
                    delivery of exceptional stakeholder value within an
                    atmosphere of trust and mutual respect.
                  </li>
                </ul>
              </div>

              <div className="col-lg-6 thumb">
                <img src={img1202} alt="Thumb" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* section 2? */}
      <WhyChooseUs />
      <ContactHome />
    </>
  );
}

export default About;
