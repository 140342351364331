import React, { useRef } from "react";
import emailjs from "@emailjs/browser";

export default function ContactForm() {
  const form = useRef();

  const clearForm = () => {
    form.current.reset();
  };

  const sendEmail = (e) => {
    e.preventDefault();

    const name = form.current.name.value;
    const email = form.current.email.value;
    const phone = form.current.phone.value;
    const country = form.current.country.value;

    let isValid = true;

    form.current.querySelectorAll(".alert-error").forEach((element) => {
      element.textContent = "";
    });

    if (!name) {
      form.current.name.nextElementSibling.textContent = "Name is required";
      isValid = false;
    }

    if (!email) {
      form.current.email.nextElementSibling.textContent = "Email is required";
      isValid = false;
    } else if (!validateEmail(email)) {
      form.current.email.nextElementSibling.textContent =
        "Invalid email format";
      isValid = false;
    }

    if (!phone) {
      console.log(form.current.phone);
      form.current.phone.nextElementSibling.textContent = "Phone is required";
      isValid = false;
    }

    if (!country) {
      console.log(form.current.country);
      form.current.country.nextElementSibling.nextElementSibling.textContent =
        "Country is required";
      isValid = false;
    }

    if (isValid) {
      emailjs
        .sendForm(
          process.env.REACT_APP_EMAILJS_SERVICE_ID,
          process.env.REACT_APP_EMAILJS_TEMPLATE_LANDING,
          form.current,
          process.env.REACT_APP_EMAILJS_PUBLIC_KEY
        )
        .then(
          (result) => {
            console.log(result.text);
          },
          (error) => {
            console.log(error.text);
          }
        );

      clearForm();

      // Show success message
      const message = form.current.querySelector(".alert-msg");
      message.textContent = "Form submitted successfully!";
      message.classList.add("alert-success");

      setTimeout(function() {
        message.textContent = "";
        message.classList.remove("alert-success");
      }, 3000);
    }
  };

  const validateEmail = (email) => {
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return pattern.test(email);
  };

  return (
    <div
      className="col-lg-12"
      style={{ padding: "60px 40px", background: "#2125299c" }}
    >
      <h5 style={{ color: "#fff" }}>Need Help?</h5>
      <h2 style={{ color: "#fff" }}>Connect With A Counselor</h2>
      <form ref={form} className="contact-form">
        <div className="row">
          <div className="col-lg-12">
            <div className="form-group">
              <input
                className="form-control"
                id="name"
                name="name"
                placeholder="Name"
                type="text"
              />
              <span className="alert-error"></span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <div className="form-group">
              <input
                className="form-control"
                id="email"
                name="email"
                placeholder="Email*"
                type="email"
              />
              <span className="alert-error"></span>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-group">
              <input
                className="form-control"
                id="phone"
                name="phone"
                placeholder="Phone"
                type="text"
              />
              <span className="alert-error"></span>
            </div>
          </div>
        </div>
        <div className="row" style={{ position: "relative", zIndex: 6 }}>
          <div className="col-lg-12">
            <div className="form-group comments">
              <select className="form-control" id="country" name="country">
                <option value="">Select Country*</option>
                <option value="usa">USA</option>
                <option value="uk">UK</option>
                <option value="australia">Australia</option>
                <option value="newzealand">New Zealand</option>
                <option value="canada">Canada</option>
                <option value="germany">Germany</option>
              </select>
              <span className="alert-error"></span>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <button
              type="submit"
              name="submit"
              id="submit"
              className="sendmailbtn"
              onClick={sendEmail}
            >
              Help me Study Abroad <i className="fa fa-paper-plane"></i>
            </button>
          </div>
        </div>
        {/* <!-- Alert Message --> */}
        <div
          className="col-lg-12 alert-notification"
          style={{ marginTop: "10px", padding: "20px" }}
        >
          <div id="message" className="alert-msg"></div>
        </div>
      </form>
    </div>
  );
}
