import React from "react";
import { abouthome } from "../image-imports";

function Facilities() {
  return (
    <div className="facilities-area">
      <div className="container">
        <div className="facilities-box">
          <div className="row">
            <div
              className="col-lg-12 offset-lg-12 info"
              style={{ paddingTop: "60px", paddingBottom: "45px" }}
            >
              <div className="">
                <div className="heading">
                  <h5>Acentrik Tech</h5>
                  <h2>Who We are</h2>
                </div>
                <div className="facilities-items">
                  <div className="item">
                    <div className="row">
                      <div
                        className="col-md-6 info"
                        style={{ paddingTop: "40px", paddingBottom: "45px" }}
                      >
                        <p>
                          Acentrik Technology Solutions LLC is an Information
                          Technology consulting services company. We deliver
                          advisory, professional managed services to enterprises
                          and clients looking for value, flexibility and
                          excellent service. As a company, our vision is to
                          create a desirable centralized marketplace and
                          contribute to increasing our client’s overall business
                          goals and objectives.
                        </p>
                        <a
                          data-animation="animated fadeInUp"
                          className="btn btn-md btn-gradient"
                          href="/aboutus"
                        >
                          Know More
                        </a>
                      </div>
                      <div
                        className="col-md-6 info"
                        style={{
                          backgroundImage: `url(${abouthome})`,
                          height: "300px",
                          backgroundSize: "cover",
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Facilities;
