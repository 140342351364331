import React from "react";
import Home from "./pages/home";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Contact from "./pages/contact";
import Services from "./pages/services";
import Coaching from "./pages/coaching";
import Universities from "./pages/universities";
import Faq from "./pages/faq";
import Team from "./pages/team";
import Partners from "./pages/partners";
import Blog from "./pages/partners";
import AboutUs from "./pages/aboutUs";
import LandingPage from "./pages/best-overseas-education-consultancy-in-hyderabad";
import OverseasEducation from "./pages/overseas-education-service-consultants-in-hyderabad";
import ImmigrationServices from "./pages/immigration-service-consultants-in-hyderabad";
import VisaProcessing from "./pages/visa-processing-service-consultants-in-hyderabad";
import Internships from "./pages/overseas-internship-service-consultants-in-hyderabad";
import EducationLoans from "./pages/education-loans-service-consultants-in-hyderabad";
import CoachingAssist from "./pages/coaching-assistance-for-visa-in-hyderabad";

import ContactLocation from "./pages/contact-location";
import ScrollToTop from "./components/scrollToTop";

function App() {
  return (
    <Router>
      <ScrollToTop />
      {/* <React.Fragment>
      <Home />
    </React.Fragment> */}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/location/:location" element={<ContactLocation />} />
        <Route path="/services" element={<Services />} />
        <Route path="/coaching" element={<Coaching />} />
        <Route path="/universities" element={<Universities />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/Team" element={<Team />} />
        <Route path="/Partners" element={<Partners />} />
        <Route path="/Blog" element={<Blog />} />
        <Route path="/AboutUs" element={<AboutUs />} />
        <Route
          path="/best-overseas-education-consultancy-in-hyderabad"
          element={<LandingPage />}
        />
        <Route
          path="/services/overseas-education-service-consultants-in-hyderabad"
          element={<OverseasEducation />}
        />
        <Route
          path="/services/immigration-service-consultants-in-hyderabad"
          element={<ImmigrationServices />}
        />
        <Route
          path="/services/visa-processing-service-consultants-in-hyderabad"
          element={<VisaProcessing />}
        />
        <Route
          path="/services/overseas-internship-service-consultants-in-hyderabad"
          element={<Internships />}
        />
        <Route
          path="/services/education-loans-service-consultants-in-hyderabad"
          element={<EducationLoans />}
        />
        <Route
          path="/services/coaching-assistance-for-visa-in-hyderabad"
          element={<CoachingAssist />}
        />
      </Routes>
    </Router>
  );
}

export default App;
