import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import { backgroundImg800 } from "../image-imports";
import Locations from "../components/locations";

function ContactArea({ children }) {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        form.current,
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  return (
    <>
      <div
        className="contact-Banner bg-cover shadow bg-gray"
        style={{
          backgroundImage: `url(${backgroundImg800})`,
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <h1>Contact Us</h1>
            </div>
          </div>
        </div>
      </div>
      {children}
      <Locations />
      <div className="contact-area default-padding-top contactpage">
        <div className="container">
          <div className="contact-items">
            <div className="row align-center">
              <div className="col-lg-4 left-item">
                <div className="info-items">
                  {/* <!-- Single Item --> */}
                  <div className="item">
                    <div className="icon">
                      <i className="fas fa-map-marked-alt"></i>
                    </div>
                    <div className="info">
                      <h5>Location</h5>
                      <p>
                        1300 W Walnut hill Lane, Suite 265E, Irving,TX 75038
                      </p>
                    </div>
                  </div>
                  {/* <!-- End Single Item --> */}
                  {/* <!-- Single Item --> */}
                  <div className="item">
                    <div className="icon">
                      <i className="fas fa-phone"></i>
                    </div>
                    <div className="info">
                      <h5>Make a Call</h5>
                      <p>+1 (469) 670-4364</p>
                    </div>
                  </div>
                  {/* <!-- End Single Item --> */}
                  {/* <!-- Single Item --> */}
                  <div className="item">
                    <div className="icon">
                      <i className="fas fa-envelope-open"></i>
                    </div>
                    <div className="info">
                      <h5>Send a Mail</h5>
                      <p>hr@acentriktech.com</p>
                    </div>
                  </div>
                  {/* <!-- End Single Item --> */}
                </div>
              </div>
              <div className="col-lg-8 right-item">
                <h5>Need Help?</h5>
                <h2>Keep in Touch</h2>
                <form ref={form} className="contact-form">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group">
                        <input
                          className="form-control"
                          id="name"
                          name="name"
                          placeholder="Name"
                          type="text"
                        />
                        <span className="alert-error"></span>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form-group">
                        <input
                          className="form-control"
                          id="email"
                          name="email"
                          placeholder="Email*"
                          type="email"
                        />
                        <span className="alert-error"></span>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <input
                          className="form-control"
                          id="phone"
                          name="phone"
                          placeholder="Phone"
                          type="text"
                        />
                        <span className="alert-error"></span>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group comments">
                        <textarea
                          className="form-control"
                          id="comments"
                          name="message"
                          placeholder="Tell Us Your Interest *"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <button
                        type="submit"
                        name="submit"
                        id="submit"
                        onClick={sendEmail}
                      >
                        Send Message <i className="fa fa-paper-plane"></i>
                      </button>
                    </div>
                  </div>
                  {/* <!-- Alert Message --> */}
                  <div className="col-lg-12 alert-notification">
                    <div id="message" className="alert-msg"></div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactArea;
